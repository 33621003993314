/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages





        // Navigation
        var navHeader   = $('.header');
        var navMobile   = $('.header__mobile');
        var navToggle   = $('.nav__toggle');

        navToggle.click(
            function(){
                navMobile.toggle('slow');
            }
        );

        // Navigation after scroll
        /*
        var navAnchor = $('#main').first().offset().top + 200;
        console.log(navAnchor);

        $(window).on('scroll', function() {
            var wst = $(window).scrollTop();
            if( wst >= navAnchor ){
                navHeader.addClass('after-scroll');
            } else {
                navHeader.removeClass('after-scroll');
            }

        });*/





        // WOW + Animate.css
        // Add class to elment

        //$('.box--gallery .image').addClass('wow animate__fadeInUp');

        //$('.prtfl__info').addClass('wow animate__fadeInRight');

        // WOW + Animate.css
        // setting
        /*var wow = new WOW({
            //offset: 200,
            animateClass: 'animate__animated',
            scrollContainer: 'body'
        });

        // init
        wow.init();*/
        /*var scrolled = false;
        $(window).on('scroll', function() {
            if (!scrolled) {
                scrolled = true;
                wow.init();
            }
        });*/





        // Magnific Popup
        // Image
        var magPopImg = $('.magnificpopup');
        magPopImg.magnificPopup({
            type: 'image',
            image: {
                titleSrc:
                function(item) {
                    return item.el.find('img').attr('alt');
                }
            }
        });

        // Video
        var magPopVideo = $('.magnificpopup--video');
        magPopVideo.magnificPopup({
            type: 'iframe'
        });

        // Gallery
        var magPopGallery = $('.magnificpopup--gallery');
        magPopGallery.each(function() { // the containers for all your galleries

            $(this).find('a').magnificPopup({
                //delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                    enabled: true
                },
                image: {
                    titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        },
                },
            });

        });





        // Slick prtfl__slider
        var prtflSldr = $('.prtfl__slider .slider');
        var prtflSldrNnr = $('.prtfl__slider .slider .inner');
        var prtflSldrNv = $('.prtfl__slider .slider__nav');
        var prtflSldrPrv = $('.prtfl__slider .slider--prev');
        var prtflSldrNxt = $('.prtfl__slider .slider--next');
        var prtflSldrScrll = $('.prtfl__slider .slider__scroll');
        var prtflSldrBar = $('.prtfl__slider .slider__bar .progress');

        function doAnimations(elements) {
            var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            elements.each(function() {
                var $this = $(this);
                var $animationDelay = $this.data('delay');
                var $animationType = 'animate__animated animate__' + $this.data('animation');
                $this.css({
                    'animation-delay': $animationDelay,
                    '-webkit-animation-delay': $animationDelay
                });
                $this.addClass($animationType).one(animationEndEvents, function() {
                    $this.removeClass($animationType);
                });
            });
        }

        prtflSldr.on('init', function(e, slick) {
            var $firstAnimatingElements = $('.item.slick-active').find('[data-animation]');
            doAnimations($firstAnimatingElements);
            $('.item.slick-current img').toggleClass('animate__prtfl__slider__image');
            $('.item .slideTot').text(slick.slideCount);
        });

        prtflSldr.on('afterChange', function(e, slick, currentSlide) {
            $('.item.slick-current img').toggleClass('animate__prtfl__slider__image');
        });

        prtflSldr.on('beforeChange', function(e, slick, currentSlide, nextSlide) {
            var $animatingElements = $('.item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
            doAnimations($animatingElements);
            $('.item.slick-current img').toggleClass('animate__prtfl__slider__image');
        });

        prtflSldr.slick({
            arrows: true,
            autoplay: true,
            autoplaySpeed: 4000,
            infinite: true,
            pauseOnHover: false,
            prevArrow: prtflSldrPrv,
            nextArrow: prtflSldrNxt,
            dots: false,
            rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });

        var time = 4;
        var isPause,
        tick,
        percentTime;

        prtflSldrNnr.on({
            mouseenter: function() {
                isPause = true;
                prtflSldr.slick('slickPause');
            },
            mouseleave: function() {
                isPause = false;
                prtflSldr.slick('slickPlay');
            }
        });

        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 10);
        }

        function interval() {
            if(isPause === false) {

                percentTime += 1 / (time+0.1);
                prtflSldrBar.css({ width: percentTime+"%" });

                if(percentTime >= 100) {
                    prtflSldr.slick('slickNext');
                    startProgressbar();

                }
            }
        }

        function resetProgressbar() {
            prtflSldrBar.css({ width: 0+'%' });
            clearTimeout(tick);
        }

        startProgressbar();

        prtflSldrPrv.click( function(){
            startProgressbar();
        });
        prtflSldrNxt.click( function(){
            startProgressbar();
        });

        // Slider Scroll
        prtflSldrScrll.click( function(){
            $('html, body').animate({ scrollTop: ( $('#main .box').first().offset().top - 80 ) }, 500);
        } );





        // Box Gallery Slider
        // Slick box--gallery--slider
        var gllrySldr = $('.box--gallery--slider .images');
        gllrySldr.slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            pauseOnHover: true,
            dots: true,
            rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
